<template>
  <section
    class="app-container app-flex-col-container verify-component"
    v-loading="sizeNum > createdPsdNum"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="content flex-one-page">
      <el-alert type="warning" show-icon>
        <div class="text-medium">注意事项：</div>
        <div class="text-medium">1，以参考尺码的组件为标准，校验其他尺码组件顺序</div>
        <div class="text-medium">
          2，校验过程中，系统会提示组件是否异常，异常的情况下需要去调整顺序或者旋转角度达到跟参考尺码组件对应
        </div>
        <div class="text-medium">
          3，校验过程中，未提示的组件，也要检查顺序是否正确（两个组件一样的情况下系统无法给出异常提示）
        </div>
      </el-alert>

      <div class="product-component-wrapper">
        <product-component :data="baseSize" @psdCreated="createdPsdNum++"></product-component>
        <div class="actual-product-list">
          <div v-for="item in size" :key="item.id" class="actual-product-item">
            <RotateProductComponent
              ref="product"
              type="1"
              :baseSize="baseSize"
              :data="item"
              @psdCreated="createdPsdNum++"
            ></RotateProductComponent>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <el-button @click="$router.back()"> 取消 </el-button>
      <el-button type="primary" @click="doSubmit" :loading="loading.submit"> 保存 </el-button>
    </div>
  </section>
</template>

<script>
import productComponent from './module/productComponent'
import RotateProductComponent from './module/rotateProductComponent'
import { getReferenceSize, changeGroup } from '@/api/product/productApi'

export default {
  name: 'verifyComponent',
  components: {
    productComponent,
    RotateProductComponent
  },
  data() {
    return {
      loading: {
        page: false,
        submit: false
      },
      baseSize: {},
      size: {},
      sizeNum: 1,
      createdPsdNum: 0
    }
  },
  created() {
    getReferenceSize(this.$route.query.id)
      .then((res) => {
        this.formatResult(res.detail)
        return res
      })
      .then(
        ({
          detail: {
            base_size: [base_size],
            size
          }
        }) => {
          console.log('base_size', base_size)

          this.baseSize = base_size
          size = size.filter(({ kl_docs: [{ kl_psd_path } = {}] = [] }) => kl_psd_path)
          console.log('size', size)
          this.size = size
          this.sizeNum = size.length + (base_size ? 1 : 0)
          // console.log(base_size, size);
        }
      )
  },
  methods: {
    //将java的数据结构转换成之前的python的数据结构
    formatResult(data) {
      //referSize参考尺码信息， sizeList 其他尺码信息
      const { referSize, sizeList } = data
      //referSizeLayerGroupList 参考尺码图层信息， referSizePsdConfig 参考尺码全局信息
      const { referSizeLayerGroupList, referSizePsdConfig } = referSize

      const {
        psdPath: base_psd_path,
        imageHeight: kl_image_height,
        imageWidth: kl_image_width,
        vdpi: kl_v_dpi,
        hdpi: kl_h_dpi
      } = referSizePsdConfig

      //格式化全局信息
      referSizePsdConfig.base_psd_path = base_psd_path
      referSizePsdConfig.kl_image_height = kl_image_height
      referSizePsdConfig.kl_image_width = kl_image_width
      referSizePsdConfig.kl_v_dpi = kl_v_dpi
      referSizePsdConfig.kl_h_dpi = kl_h_dpi

      referSizeLayerGroupList.map((referSizeLayer) => {
        const { referSizeLayerGroupImageList } = referSizeLayer
        referSizeLayerGroupImageList.map((referSizeLayerGroupImage) => {
          //格式化参考尺码中的图片列表
          const { imagePath } = referSizeLayerGroupImage
          referSizeLayerGroupImage.img_url = `${imagePath}`
        })
        referSizeLayer.base_kl_images = referSizeLayerGroupImageList
      })

      referSize.base_kl_docs = [referSizePsdConfig]

      //更新其他非参考尺码
      sizeList.map((size) => {
        let { sizeLayerGroupList, sizeName, psdPath, sizePsdConfig } = size
        sizePsdConfig = sizePsdConfig || {}
        sizeLayerGroupList = sizeLayerGroupList || []
        const {
          psdPath: kl_psd_path,
          imageHeight: kl_image_height,
          imageWidth: kl_image_width,
          vdpi: kl_v_dpi,
          hdpi: kl_h_dpi
        } = sizePsdConfig || {}

        sizePsdConfig.kl_psd_path = kl_psd_path
        sizePsdConfig.kl_image_height = kl_image_height
        sizePsdConfig.kl_image_width = kl_image_width
        sizePsdConfig.kl_v_dpi = kl_v_dpi
        sizePsdConfig.kl_h_dpi = kl_h_dpi

        size.size_name = sizeName
        sizeLayerGroupList.map((layer) => {
          let { sizeLayerGroupImageList } = layer
          sizeLayerGroupImageList = sizeLayerGroupImageList || []
          sizeLayerGroupImageList.map((layerImg) => {
            layerImg.img_url = `${layerImg.imagePath}`
          })
          layer.kl_images = sizeLayerGroupImageList
        })

        size.kl_groups = sizeLayerGroupList
        size.kl_docs = [sizePsdConfig]
      })

      referSizeLayerGroupList.map((referSize) => {
        const { showRotate, quickDesignLeft, quickDesignTop, referSizeLayerGroupImageList } = referSize
        referSize.show_rotate = showRotate
        referSize.quick_design_left = quickDesignLeft
        referSize.quick_design_top = quickDesignTop
      })

      referSize.base_kl_groups = referSizeLayerGroupList

      data.size = sizeList
      data.base_size = [referSize]
      return data
    },

    doSubmit() {
      this.loading.submit = true
      let tempArr = []
      this.$refs.product.forEach((product) => {
        tempArr = tempArr.concat(product.getChangeData())
      })
      if (tempArr.length) {
        changeGroup({
          list: tempArr
        })
          .then((res) => {
            if ($SUC(res)) {
              this.$message.success('校验成功')
              this.$router.back()
            }
          })
          .finally(() => {
            this.loading.submit = false
          })
      } else {
        this.$confirm('无修改内容, 是否返回?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$router.back()
          })
          .finally(() => {
            this.loading.submit = false
          })
      }
    }
  }
}
</script>

<style lang="scss">
.verify-component {
  .flex-one-page {
    .el-alert {
      flex: 0 0 93px;
    }
    .product-component-wrapper {
      flex: 1;
      height: calc(100% - 74px);
    }
  }
}
</style>
