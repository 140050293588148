<template>
  <el-card shadow="never" :class="['product-component', option.wrapperClass]">
    <template #header>
      <div v-if="option.composeImage" class="compose-image" :style="{ width: psdInfo.width, height: psdInfo.height }">
        <el-image :src="psdInfo.src" style="width: 100%; height: 100%">
          <img slot="error" src="@/assets/images/default.png" width="123" height="56" alt class="block" />
        </el-image>
        <div v-for="(style, key) in coods" :key="key" :style="style" class="image-desc">{{ style.order + 1 }}</div>
      </div>
      <div class="text-center">
        <template v-if="data.size_name">
          <div>{{ data.size_name }}</div>
          <div v-if="isShelves" class="text-danger">(上架)</div>
        </template>
        <div v-if="option.remark" class="remark">参考尺码</div>
      </div>
    </template>
    <draggable v-model="list" v-bind="dragOptions" class="product-component-list" @change="onChange">
      <div v-for="(item, index) in list" :key="item.id" class="product-component-item rotate-product-component-item">
        <div class="item-content-wrapper">
          <div class="card-wrapper">
            <svg-icon v-if="option.more" icon-class="menu" />
            <el-card shadow="never">
              <span class="text-red ml10">{{ index + 1 }}</span>
              <el-image
                :src="option.getItemImg(item).img_url"
                :preview-src-list="[option.getItemImg(item).img_url]"
                fit="contain"
                :style="`transform: rotate(${-item.rotate}deg)`"
              >
                <img slot="error" src="@/assets/images/default.png" width="40" height="40" alt class="block" />
              </el-image>
              <div class="text-cut">
                <div class="text-cut" :title="item.name">{{ item.name }}</div>
                <div class="checked-text">
                  {{ checkedTextArr[index] }}
                </div>
              </div>
            </el-card>
          </div>

          <div class="done-list" v-if="!option.remark">
            <el-slider
              ref="slider"
              size="mini"
              :step="0.01"
              :rotate="rotate(item)"
              v-model="item.rotate"
              :disabled="isDisabled"
              :min="0"
              :max="360"
              :marks="marks"
              :show-input-controls="false"
              :format-tooltip="formatTooltip"
            ></el-slider>
            <el-input-number
              size="mini"
              v-model="item.rotate"
              :disabled="isDisabled"
              :controls="false"
              :min="0"
              :max="360"
              label="请输入角度"
            ></el-input-number>
          </div>
        </div>
        <!-- <el-button v-if="option.rotate" type="text" @click.stop.prevent="onRotate(item.id)">旋转</el-button> -->
      </div>
    </draggable>
  </el-card>
</template>

<script>
// draggable 配置：https://segmentfault.com/a/1190000021376720
import draggable from 'vuedraggable'
import { deepClone } from '@/components/avue/utils/util'
import { getImgRotateSize } from '@/utils'
import { SHELVES } from '@/utils/constant'

export default {
  components: {
    draggable
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },

    //参考尺码
    baseSize: {
      type: Object,
      default() {
        return {}
      }
    },

    type: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      list: [],
      checkedTextArr: [],
      psdInfo: {
        src: '',
        scale: '',
        width: '',
        height: ''
      },
      coods: {},

      oData: [],

      marks: {
        90: '90°',
        180: '180°',
        270: '270°'
      }
    }
  },
  computed: {
    option({ type }) {
      let option = {}
      switch (type) {
        case '1':
          option = {
            wrapperClass: 'actual-product',
            more: true,
            rotate: true,
            draggable: true,
            props: {
              docs: 'kl_docs',
              list: 'kl_groups',
              psdPath: 'kl_psd_path'
            },
            getItemImg(item) {
              // console.log('item', item)
              if (item && item.kl_images && item.kl_images[0]) return item.kl_images[0]
              return {
                img_url: ''
              }
            }
          }
          break

        default:
          option = {
            remark: true,
            props: {
              docs: 'base_kl_docs',
              list: 'base_kl_groups',
              psdPath: 'base_psd_path'
            },
            getItemImg(item) {
              if (item && item.base_kl_images && item.base_kl_images[0]) return item.base_kl_images[0]
              return {
                img_url: ''
              }
            }
          }
      }
      return {
        composeImage: true,
        ...option,
        ...this.$attrs
      }
    },
    dragOptions({ option, isDisabled }) {
      return {
        animation: 200,
        group: {
          name: 'description',
          // pull用来定义从这个列表容器移动出去的设置，true/false/'clone'/function
          /*true:列表容器内的列表单元可以被移出；
           false：列表容器内的列表单元不可以被移出；
           'clone'：列表单元移出，移动的为该元素的副本；
           function：用来进行pull的函数判断，可以进行复杂逻辑，在函数中return false/true来判断是否移出；*/
          pull: option.pull || false,
          // put用来定义往这个列表容器放置列表单元的的设置，true/false/['foo','bar']/function
          /*true:列表容器可以从其他列表容器内放入列表单元；
           false：与true相反；
           ['foo','bar']：这个可以是一个字符串或者是字符串的数组，代表的是group配置项里定义的name值；
           function：用来进行put的函数判断，可以进行复杂逻辑，在函数中return false/true来判断是否放入；*/
          put: option.put || false
        },
        disabled: !option.draggable || isDisabled,
        ghostClass: 'ghost'
      }
    },
    rotate() {
      return (item) => {
        let item1 = this.coods[item.id]
        this.$set(item1, 'rotate', item.rotate)
        return 0
      }
    },
    isDisabled({ isShelves }) {
      return isShelves
    },
    isShelves({ data }) {
      return data.status === SHELVES
    }
  },
  watch: {
    data: {
      handler() {
        let list = this.data[this.option.props.list]
        if (list) {
          this.list = deepClone(list)
          this.getPsdInfo()
          this.getCoodDom(this.list)
        }
      },
      deep: true,
      immediate: true
    },
    coods: {
      handler(newVal) {
        this.coodsTimer && clearTimeout(this.coodsTimer)
        this.coodsTimer = setTimeout(() => {
          const coodsData = Object.values(newVal).sort((a, b) => a.order - b.order)
          console.log('coodsData', coodsData)
          coodsData.map((item, index) => {
            this.checkedCood(index, coodsData)
          })
        }, 200)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async checkedCood(index, coodsData) {
      const { base_kl_groups } = this.baseSize
      if (!coodsData[index] || !base_kl_groups[index]) {
        return ''
      }
      let { height: coodH, width: coodW, rotate: coodR } = coodsData[index]
      coodH = parseFloat(coodH)
      coodW = parseFloat(coodW)
      let {
        base_kl_images: [{ height, width, img_url }]
      } = base_kl_groups[index]

      if (coodR > 0) {
        const { w, h } = await getImgRotateSize({
          src: img_url,
          angle: coodR
        })
        width = w
        height = h
        console.log('width, height', width, height)
        console.log('w, h', w, h)
      }

      const [cRadio, radio] = [(coodW / coodH).toFixed(2), (width / height).toFixed(2)]

      if (cRadio == radio) {
        this.checkedTextArr.splice(index, 1, '')
        return ''
      }
      this.checkedTextArr.splice(index, 1, '(异常)')
      console.log(' this.checkedTextArr', this.checkedTextArr)
      return '(异常)'
    },

    //获取旋转之后模块的画布
    getCanvaRactsSize({ height, width, rotate }) {
      console.log('getCanvaRactsSize', height, width)
      //旋转中心
      const { abs } = Math
      let [w, h] = [0, 0]
      const [oX0, oY0] = [0, 0]
      const [rX0, rY0] = [-width / 2, height / 2]
      const [rX1, rY1] = [width / 2, height / 2]
      const [rX2, rY2] = [width / 2, -height / 2]
      const [rX3, rY3] = [-width / 2, -height / 2]

      // const { x: x0, y: y0 } = getRotatePoint({ rX: rX0, rY: rY0, oX: oX0, oY: oY0, angle: rotate })
      // const { x: x1, y: y1 } = getRotatePoint({ rX: rX1, rY: rY1, oX: oX0, oY: oY0, angle: rotate })
      // const { x: x2, y: y2 } = getRotatePoint({ rX: rX2, rY: rY2, oX: oX0, oY: oY0, angle: rotate })
      // const { x: x3, y: y3 } = getRotatePoint({ rX: rX3, rY: rY3, oX: oX0, oY: oY0, angle: rotate })

      const { x: x0, y: y0 } = getRotatePoint({
        rX: rX0,
        rY: rY0,
        oX: oX0,
        oY: oY0,
        angle: rotate,
        col: width,
        row: height
      })
      const { x: x1, y: y1 } = getRotatePoint({
        rX: rX1,
        rY: rY1,
        oX: oX0,
        oY: oY0,
        angle: rotate,
        col: width,
        row: height
      })
      const { x: x2, y: y2 } = getRotatePoint({
        rX: rX2,
        rY: rY2,
        oX: oX0,
        oY: oY0,
        angle: rotate,
        col: width,
        row: height
      })
      const { x: x3, y: y3 } = getRotatePoint({
        rX: rX3,
        rY: rY3,
        oX: oX0,
        oY: oY0,
        angle: rotate,
        col: width,
        row: height
      })
      if (rotate <= 90 || (rotate > 180 && rotate <= 270)) {
        console.log('x1', x1)
        console.log('x3', x3)
        w = abs(x1) + abs(x3)
        h = abs(y0) + abs(y2)
      } else {
        w = abs(x0) + abs(x2)
        h = abs(y1) + abs(y3)
      }
      return {
        w,
        h
      }
    },

    // getCanvaRactsSize({ height, width, rotate }) {
    //   //旋转中心
    //   const { abs } = Math
    //   let [w, h] = [0, 0]
    //   const [oX0, oY0] = [width / 2, height / 2]
    //   const [rX0, rY0] = [0, height]
    //   const [rX1, rY1] = [width, height]
    //   const [rX2, rY2] = [width, 0]
    //   const [rX3, rY3] = [0, 0]

    //   const { x: x0, y: y0 } = getRotatePoint({ rX: rX0, rY: rY0, oX: oX0, oY: oY0, angle: rotate })
    //   const { x: x1, y: y1 } = getRotatePoint({ rX: rX1, rY: rY1, oX: oX0, oY: oY0, angle: rotate })
    //   const { x: x2, y: y2 } = getRotatePoint({ rX: rX2, rY: rY2, oX: oX0, oY: oY0, angle: rotate })
    //   const { x: x3, y: y3 } = getRotatePoint({ rX: rX3, rY: rY3, oX: oX0, oY: oY0, angle: rotate })
    //   if (rotate <= 90 || (rotate > 180 && rotate <= 270)) {
    //     w = abs(x1) + abs(x3)
    //     h = abs(y0) + abs(y2)
    //   } else {
    //     w = abs(x0) + abs(x2)
    //     h = abs(y1) + abs(y3)
    //   }
    //   return {
    //     w,
    //     h
    //   }
    // },

    formatTooltip(val) {
      return val + '°'
    },

    onChange() {
      let { coods, list, oData } = this
      list.map(({ id }, index) => {
        coods[id].order = index
        // Object.assign(coods[id], oData[index])
      })
      this.$emit('change', coods)
    },

    onRotate(id) {
      let item = this.coods[id]
      this.$set(item, 'rotate', !item.rotate)
    },

    getChangeData() {
      let { coods } = this
      let tempArr = []
      for (const coodsKey in coods) {
        let { id, order, oldOrder, rotate, oldRotate } = coods[coodsKey]
        if (order !== oldOrder || rotate !== oldRotate) {
          tempArr.push({
            id,
            order,
            rotate
          })
        }
      }
      return tempArr
    },

    getCoodDom(list) {
      let {
        psdInfo: { scale }
      } = this
      this.oData = []
      list.map((item, index) => {
        let { width, height, left, top } = this.option.getItemImg(item)
        let siteInfo = {
          width: width * scale + 'px',
          height: height * scale + 'px',
          left: left * scale + 'px',
          top: top * scale + 'px',
          order: item.order
        }
        this.oData.push(siteInfo)
        this.$set(this.coods, item.id, {
          ...siteInfo,
          id: item.id,
          order: item.order,
          oldOrder: item.order,
          rotate: item.rotate,
          oldRotate: item.rotate
        })
      })
    },
    getPsdInfo() {
      let {
        props: { docs, psdPath }
      } = this.option
      let { kl_image_width, kl_image_height, [psdPath]: oPsdPath } = this.data[docs]?.[0] || {}
      this.getPsdPath(oPsdPath)

      let { psdInfo } = this
      psdInfo.scale = Math.min(250 / kl_image_width, 188 / kl_image_height)
      psdInfo.width = kl_image_width * psdInfo.scale + 'px'
      psdInfo.height = kl_image_height * psdInfo.scale + 'px'
    },
    getPsdPath(url = '') {
      this.PSD.fromURL(url).then((psd) => {
        this.psdInfo.src = psd.image.toPng().src
        this.$nextTick(function () {
          console.log('psdCreated')
          this.$emit('psdCreated', psd)
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.rotate-product-component-item.rotate-product-component-item {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  .checked-text {
    color: $color-danger;
    font-size: 12px;
    line-height: 14px;
  }
  .item-content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .card-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .done-list {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .el-slider {
        flex: 1;
      }
      .el-input-number {
        flex: 0 0 80px;
        margin-left: 10px;
      }
    }
  }
  .item-content-wrapper::after {
    content: '';
    position: absolute;
    height: 1px;
    left: -25px;
    right: -25px;
    bottom: 0;
    background: $border-color;
  }
}
</style>
